@tailwind base;
@tailwind components;

@layer base {
  html {
    @apply scroll-smooth text-black;
  }

  @font-face {
    font-family: "DM Sans";
    font-weight: 400;
    src: url("/fonts/DMSans-Regular.ttf") format("ttf");
  }

  @font-face {
    font-family: "DM Sans";
    font-weight: 500;
    src: url("/fonts/DMSans-Medium.ttf") format("ttf");
  }

  @font-face {
    font-family: "DM Sans";
    font-weight: 500;
    src: url("/fonts/DMSans-MediumItalic.ttf") format("ttf");
  }

  @font-face {
    font-family: "DM Sans";
    font-weight: 700;
    src: url("/fonts/DMSans-Bold.ttf") format("ttf");
  }

  @font-face {
    font-family: "DM Sans";
    font-weight: 700;
    src: url("/fonts/DMSans-BoldItalic.ttf") format("ttf");
  }

  body {
    font-family: "DM Sans", sans-serif;
  }

  ::selection {
    @apply bg-accent text-black-dark;
  }

  .view-in-ar {
    @apply lg:top-8 lg:left-0 lg:translate-x-0;
  }

  .view-in-ar img {
    height: clamp(1.375rem, 1.0577rem + 1.4103vw, 2.75rem);
    width: clamp(1.375rem, 1.0577rem + 1.4103vw, 2.75rem);
  }
}

@tailwind utilities;

@layer utilities {

  .swiper .swiper-pagination-bullet-active,
  .swiper-pagination-bullets .swiper-pagination-bullet-active {
    @apply w-4;
  }

  .swiper-pagination-bullets,
  #brandModelsPagination {
    @apply w-full !important;
  }

  .swiper-button-disabled {
    @apply cursor-not-allowed opacity-50;
  }
}

.compareItem {
  width: 700px;
}

.bg-gredient {
  background-image: linear-gradient(179deg, #fff 1%, rgba(200, 241, 226, 0.28) 16%,
      rgba(200, 241, 226, 0.46) 70%, #fff 100%);

}

.hero1 {
  width: 405px;
}

.customBtn {
  background: transparent;
  display: inline-block;
  outline: none;
  padding: 8px 15px;
  border: solid 1px #c2d4ce;
  border-radius: 40px;
  text-align: center;
  min-width: 80px;
}

.compTextBox {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.btnFill {
  padding: 8px 15px;
  border-radius: 12px;
  background-color: #137359;
  color: #fff;
  outline: none;
  border: solid 1px #137359;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 150px;
  height: 56px;

}

.btnOutline {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 15px;
  border-radius: 12px;
  border: solid 1px #137359;
  outline: none;
  background: transparent;
  color: #137359;
  min-width: 150px;
  height: 56px;
}


.footerIcon a svg {
  width: 40px;
}

.heading1 {
  font-size: 2.625rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
}

.themefont {
  font-size: 1rem;
  font-weight: 500;
}

.textBlock {
  max-width: 825px;
  width: 100%;
}

.contentApi {
  margin-top: 1.3rem;
}

.contentApi p,
.contentApi ul {
  margin-top: 1.3rem;
}

.threeDBOxContainer {
  flex: 1 1;
  display: flex;
  justify-content: end;
}

.threeDBOx {
  width: 650px;
  height: 530px;
  object-fit: contain;
  border-radius: 30px;
  background-color: #fff;
  padding: 20px;
}

.apiSectionContaint {
  display: flex;
  padding: 4rem 0;
  align-items: center;
  justify-content: space-between;
}

.apili li {
  list-style-type: disc;
  margin-left: 2.25rem;
  margin-top: 1.1rem;
}

.btnPlane {
  display: inline-block;
  padding: 7px 25px 8px;
  border-radius: 32px;
  border: solid 1px #c2d4ce;
  text-align: center;
}

.custom-handle {
  background-color: red;
  width: 10px;
  height: 100%;
  border-radius: 5px;
}

.compareBox {
  border-radius: 34px;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.071);
  border: solid 1px #c2d4ce;
}

.ctaonmob {
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-bottom: 40px;
  display: none;
}

.ctaonmob button,
.ctsbottom button {
  width: 150px;
}

.ctsbottom {
  align-items: center;
  justify-content: center;
  gap: 15px;
  display: flex;
}

.video2 {
  display: none;
}

.alertBox {
  width: 100%;
  min-height: 120px;
  object-fit: contain;
  background: linear-gradient(94deg, rgba(75, 206, 139, 0.58) 3%, rgba(162, 215, 144, 0.53) 36%, rgba(162, 215, 144, 0.51) 65%, rgba(75, 206, 139, 0.35) 94%);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 30px;
  position: relative;
  overflow: hidden;
  flex-direction: column;
}

canvas.tapeCanvas {
  height: 100%;
}

.alertTxt {
  font-size: 1.5rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1;
  text-align: center;
  color: #444;
  line-height: 1.4;
}

.alertLink {
  font-size: 1.1rem;
  font-weight: 600;
  letter-spacing: normal;
  text-align: center;
  color: #137359;
  text-decoration: underline;
}

.tape {
  position: absolute;
  left: 30px;
}

.linksbtn {
  display: flex;
  gap: 10px;
  font-size: 1.1rem;
  font-weight: 600;
  letter-spacing: normal;
  text-align: center;
  color: #137359;
}

.pagearrow {
  left: 4px;
  right: 5px
}

.calendly-inline-widget {
  height: 700px !important;
  width: 100% !important;
  margin-bottom: 50px;
  margin-top: -60px;
}

section.apiSection {
  padding-bottom: 2px;
}

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

body {
  line-height: 1.4;
}

img {
  max-width: 100%;
}

.up_slider {
  width: 100%;
}

.up_slider-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.up_slide-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 90px;
  /* object-fit: contain;
    overflow: hidden; */
}

.parntner_slider {
  border: 0.5px solid #C2D4CE;
  border-radius: 20px;
  padding: 55px 40px;
  width: 90%;
  max-width: clamp(20rem, -0.7692rem + 92.3077vw, 110rem);
  margin: 60px auto;
}

.partner_supporting {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: DM Sans;
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  color: #999999;
}

.patnerSlide-img {
  height: 40px;
}

img.patnerSlide-img2 {
  height: 40px;
}

.revolutionized {
  max-width: clamp(20rem, -0.7692rem + 92.3077vw, 110rem);
  margin: 30px auto;
  position: relative;
}

/* the parent */
.sld1 .slick-list {
  margin: 0 -3vw;
}
.revolution_card-box {
  width: 100%;
  display: inline-block;
  padding: 35px 3vw;
}

.revolution_card {
  border: 1px solid #C2D4CE;
  border-radius: 30px;
  background: #fff;
}

.revolution_img-box {
  height: 250px;
  width: 100%;
  border-radius: 30px 30px 0 0;
  background: #EDF5F1;
  border-bottom: 1px solid #C2D4CE;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.revolution_img {
  /* width: 200px; */
  object-fit: contain;
  border-radius: 30px 30px 0 0;
    overflow: hidden;
}

.revolution_logo img {
  height: 30px;
}

.revolution_text-content {
  padding: 20px 15px;
}


.revolution_desc {
  font-family: DM Sans;
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  /* padding: 10px 0; */
  color: #444444;
}

.revolution_desc::-webkit-scrollbar {
  display: none;
}

.revolution_text {
  font-family: DM Sans;
  font-size: 1.125rem;
  font-weight: 500;
  text-align: left;
  color: #444444;
  margin: 15px 0 0px 0;
}

.row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
}

.row2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

img.upSlideimg {
  width: 100%;
}

.upside_slider {
  padding: 30px;
  max-width: clamp(20rem, -0.7692rem + 92.3077vw, 110rem);
  margin: 30px auto;
}

.quotes {
  width: 55px;
  height: 55px;
  position: absolute;
  top: -22px;
  left: 0;
}

.revolution_title {
  width: 65%;
  font-family: DM Sans;
  font-size: 2.6375rem;
  font-weight: 700;
  text-align: center;
  margin: 60px auto 15px;
}

.award_cards {
  max-width: clamp(20rem, -0.7692rem + 92.3077vw, 110rem);
  margin-left: auto;
  margin-right: auto;
}

.cardgap {
  padding: 35px 3vw;
}

.awards_title {
  font-size: 42px;
  font-weight: 700;
  text-align: center;
  margin: 40px auto 5px;
}

.award_card {
  border: 1px solid #C2D4CE;
  background: #FFFFFF;
  border-radius: 30px;
}

.award_card-content {
  padding: 8px 25px 25px;
}

.award_card-img {
  width: 100%;
  height: 250px;
  border-radius: 30px 30px 0 0;
  overflow: hidden;
  object-fit: cover;
}

.award_card-img img {
  min-width: 100%;
  min-height: 100%;

}

.award_card-text span {
  font-size: 1rem;
  font-weight: 700;
  text-align: left;
  color: #444444;
}

.award_read {
  font-size: 1rem;
  font-weight: 700;
  text-align: left;
  color: #137359;
  text-decoration: underline;
}

.award_card-text {
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  color: #444444;
  height: 70px;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.award_card-text::-webkit-scrollbar {
  display: none;
}

.award_card-text:hover {
  -webkit-line-clamp: 5;
  overflow: scroll;
}

.revolution_desc {
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.revolution_desc:hover {
  -webkit-line-clamp: unset;
  /* overflow: scroll; */
}

.award_card-logo {
  margin: 10px 0;
  height: 40px;
}
.award_card-logo img{
  max-height: 100%;
}

.section.awars {
  position: relative;
}

.slider_btns {
  display: flex;
  align-items: center;
  gap: 20px;
  /* width: 100px; */
  position: absolute;
  right: -5px;
  top: -65px;
}

.slider_btns button {
  padding: 4px 5px;
}

.awardsSld-btn button {
  padding: 4px;
}

.awardsSld-btn {
  top: -50px;
    right: -5px;
}

.revolution_title_mob {
  display: none;
}

.revolution_logo {
  margin-bottom: 16px;
}

@media (max-width:800px) {
  .revolution_title {
    width: 100%;
    font-family: DM Sans;
    font-size: 1.44rem;
    font-weight: 700;
    text-align: center;
  }
}

@media (max-width:768px) {
  body {
    overflow-x: hidden;
    background: #EDF5F1;
  }
  .awards_title {
    font-size: 1.4rem;
  }
  .upside_slider {
    padding: 30px 0;
  }

  .row {
    gap: 15px;
    /* justify-content: center; */
  }

  .calendly-inline-widget {
    height: 1080px !important;
    width: 98% !important;
    margin-bottom: 0;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .tape {
    top: -15px;
    left: auto;
  }

  .alertBox {
    flex-direction: column;
    gap: 10px;
  }

  .alertTxt {
    font-size: 1.2rem;
  }

  .alertLink {
    font-size: .8rem;
  }

  .linksbtn {
    font-size: .8rem;
  }

  .mobfull {
    width: 100%;
  }

  .video1 {
    display: none;
  }

  .video2 {
    display: block;
  }

  .rugsec {
    background-color: #edf5f1;
  }

  .btnOutline,
  .btnFill {
    height: 36px;
  }

  .header {
    background-color: #edf5f1;
  }

  .ctaonmob {
    display: flex !important;
  }

  .mob-center {
    text-align: center;
  }

  .btnBx {
    flex-direction: column;
  }

  .apiSectionContaint {
    flex-direction: column;
    padding: 25px 12px;
  }

  .heading1 {
    font-size: 1.625rem;

  }

  .textBlock {
    order: 2;
  }

  .threeDBOx {
    width: 100%;
  }

  .threeDBOxContainer {
    width: 100%;
    order: 1;
  }

  .bg-gredient {
    background-image: linear-gradient(to bottom, #edf5f1 1%, rgba(200, 241, 226, 0.37) 36%, rgba(200, 241, 226, 0.65) 58%, #edf5f1 78%);

  }

  .compTextBox {
    order: 1;
    padding: 40px 0;
  }

  .compareBox {
    order: 2;
    border-radius: 0;
    border-left: 0;
    border-right: 0;
  }

  .hero1 {
    width: 100%;
    order: 2;
  }

  .compareOuter {
    flex-direction: column;
  }

  .compareItem {
    width: 100%;
  }

  .up_slide-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 60px;
  }

  .row2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
  }

  .parntner_slider {
    padding: 35px 20px;
    margin: 20px auto;
  }

  .slider_btns {
    display: none;

  }
  .sld1 .slick-list {
    margin: 0 -10px; padding: 0 50px;
  }
  
  .revolution_card-box {
    padding-left: 10px;
    padding-right: 10px;
  }
  .cardgap {
    padding-left: 10px;
    padding-right: 10px;
}
  .quotes {
    display: none;
  }
}